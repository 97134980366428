// components
import DetailRow from "../DetailRow";
import { Heading } from "@/shared/components/ui";
import { PolicySummaryConfig } from "./index";
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "@/shared/components/ui/accordion";

// utils
import { UIUtils } from "@/shared/utils/UIUtils";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";

// types
import { Policy, Quote } from "@/shared/types/Quote.interface";
import { PetBreed } from "spot-types/entities/PetBreed";
interface PolicySummaryContentProps {
    quote: Quote;
    breeds: PetBreed[] | undefined;
    config?: PolicySummaryConfig;
    isAnnualBilling: boolean;
}

interface PolicySummaryRowProps {
    policy: Policy;
    breedName: string | undefined;
    isAnnualBilling: boolean;
    config?: PolicySummaryConfig;
    quote: Quote;
}

const PolicySummaryRow = (props: PolicySummaryRowProps) => {
    const { policy, breedName, isAnnualBilling, config, quote } = props;
    // Set config detaults:
    const { enableAccordionToggle = true, showPreDiscountPrice = true, useGenericDiscountLabel = false } = config ?? {};

    const {
        name,
        species,
        petAge,
        gender,
        basePrice,
        accidentCreateDate,
        accidentCoverageDate,
        illnessCoverageDate,
        wellnessCoveragePrice,
        wellnessCoverageDate,
        annualLimit,
        annualDeductible,
        reimbursementRate
    } = CoverageUtils.formatPolicyDetails(policy);
    const genderText = gender === "M" ? `Male` : `Female`;
    const triggerText = "Toggle policy";

    const hasDifferentAccidentIllnessDates = !!illnessCoverageDate && accidentCoverageDate !== illnessCoverageDate;

    // Discounts
    const discounts = policy?.discounts ?? [];
    const hasDiscount = discounts.length > 0;
    const totalDiscount = hasDiscount ? discounts.map(discount => discount.amount ?? 0).reduce((previousValue, currentValue) => (previousValue ?? 0) + (currentValue ?? 0), 0) : 0;
    // Needed for generic discount label
    const discountAmount = policy?.discountAmount?.value ?? 0;

    // Pricing
    const planTotal = Number(basePrice?.value ?? 0);
    const planPremium = planTotal - (wellnessCoveragePrice ?? 0);
    const planTotalAdjusted = planTotal + totalDiscount;

    const renderCoverageDetails = () => {
        if (hasDifferentAccidentIllnessDates) {
            return (
                <>
                    {!!accidentCoverageDate && (
                        <DetailRow
                            label="Accident Coverage Starts"
                            value={CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                            valueClass="font-semibold"
                            wrapperClass="text-xs leading-5"
                        />
                    )}
                    {!!illnessCoverageDate && (
                        <DetailRow
                            label="Illness Coverage Starts"
                            value={CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                            valueClass="font-semibold"
                            wrapperClass="text-xs leading-5"
                        />
                    )}
                </>
            );
        } else {
            if (!!accidentCoverageDate && !!illnessCoverageDate) {
                return (
                    <DetailRow
                        label="Accident & Illness Coverage Starts"
                        value={CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                        valueClass="font-semibold"
                        wrapperClass="text-xs leading-5"
                    />
                );
            } else if (!!accidentCoverageDate) {
                return (
                    <DetailRow
                        label="Accident Coverage Starts"
                        value={CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                        valueClass="font-semibold"
                        wrapperClass="text-xs leading-5"
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
            <AccordionItem value={policy.id!} className="text-content-primary">
                <Heading level="h3" className="text-base font-bold leading-6">
                    {name}’s Coverage
                </Heading>
                <div className="flex flex-row justify-between gap-4">
                    <p className="text-xs leading-5">
                        {species} - {petAge}, {genderText}, {breedName}
                    </p>
                    <div className="relative flex">
                        <div>
                            {hasDiscount && showPreDiscountPrice && (
                                <span className="mr-1 block text-right text-content-secondary line-through sm:inline-block">{UIUtils.formatCurrency(planTotal)}</span>
                            )}
                            <b className={hasDiscount && showPreDiscountPrice ? "text-content-success" : ""}>{UIUtils.formatCurrency(planTotalAdjusted)}</b>/
                            {isAnnualBilling ? "yr" : "mo"}
                        </div>
                        {enableAccordionToggle && (
                            <AccordionTrigger aria-label={triggerText} className="ml-1 mt-1 h-4 p-1">
                                <span className="hidden">{triggerText}</span>
                            </AccordionTrigger>
                        )}
                    </div>
                </div>
                <AccordionContent className="mt-4 pb-0">
                    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 md:gap-x-6">
                        <div className="order-1 text-xs">
                            <Heading level="h4" className="text-xs font-semibold">
                                Payment Details
                            </Heading>
                            <DetailRow
                                label={`${isAnnualBilling ? "Annual" : "Monthly"} Premium`}
                                value={`${UIUtils.formatCurrency(planPremium)}`}
                                valueClass="font-semibold"
                                wrapperClass="text-xs leading-5"
                            />
                            {!!discounts.length &&
                                !useGenericDiscountLabel &&
                                discounts.map((discount, index) => (
                                    <DetailRow
                                        key={index}
                                        label={discount.name}
                                        labelClass="text-content-success"
                                        value={`${UIUtils.formatCurrency(discount.amount ?? 0)}`}
                                        valueClass="font-semibold"
                                        wrapperClass="text-xs leading-5"
                                    />
                                ))}
                            {useGenericDiscountLabel && discountAmount > 0 && (
                                <DetailRow
                                    label="Discount"
                                    value={`${UIUtils.formatCurrency(-Math.abs(discountAmount))}`}
                                    valueClass="font-semibold"
                                    wrapperClass="text-xs text-content-success leading-5"
                                />
                            )}
                            {!!wellnessCoveragePrice && (
                                <DetailRow
                                    label="Preventive Care Coverage"
                                    value={`${UIUtils.formatCurrency(wellnessCoveragePrice ?? 0)}`}
                                    valueClass="font-semibold"
                                    wrapperClass="text-xs leading-5"
                                />
                            )}
                        </div>
                        <div className="order-1 flex flex-col gap-4 md:order-2 md:col-span-1 md:col-start-1">
                            <div>
                                <Heading level="h4" className="text-xs font-semibold">
                                    Coverage Details
                                </Heading>
                                <DetailRow label="Annual Limit" value={annualLimit} valueClass="font-semibold" wrapperClass="text-xs leading-5" />
                                <DetailRow label="Reimbursement Rate" value={reimbursementRate} valueClass="font-semibold" wrapperClass="text-xs leading-5" />
                                <DetailRow label="Annual Deductible" value={annualDeductible} valueClass="font-semibold" wrapperClass="text-xs leading-5" />
                            </div>
                        </div>
                        <div className="order-1 text-xs md:order-1">
                            <Heading level="h4" className="text-xs font-semibold">
                                Important Dates
                            </Heading>

                            {wellnessCoverageDate && (
                                <DetailRow
                                    label="Preventive Care Coverage Starts"
                                    value={CoverageUtils.calculateCoverageDate(wellnessCoverageDate)}
                                    valueClass="font-semibold"
                                    wrapperClass="text-xs leading-5"
                                />
                            )}

                            {renderCoverageDetails()}
                        </div>
                    </div>
                </AccordionContent>
            </AccordionItem>
            <div className="border-b border-stroke-tertiary"></div>
        </>
    );
};

const PolicySummaryContent = (props: PolicySummaryContentProps) => {
    const { quote, breeds, config, isAnnualBilling } = props;
    const policies = quote?.policies ?? [];
    const policyIds: Policy["id"][] = [];

    const summaryRows = policies.map((policy, index) => {
        // Policy ids needed to open all policies in the accordion by default
        policyIds.push(policy.id);
        const breedName = breeds?.find(breed => breed.id === policy.breedID)?.name ?? "";
        return <PolicySummaryRow policy={policy} breedName={breedName} isAnnualBilling={isAnnualBilling} key={index} config={config} quote={quote} />;
    });

    return (
        <Accordion type="multiple" defaultValue={policyIds.filter(id => id !== undefined) as string[]} className="flex flex-col gap-4">
            {summaryRows}
        </Accordion>
    );
};

export default PolicySummaryContent;
