import { AccordionContent, AccordionItem, AccordionTrigger } from "@/shared/components/ui/accordion";
import React from "react";

const FaqItem = ({ question, answer }: { question: string; answer: string }) => {
    return (
        <AccordionItem value={question} className="mb-1">
            <div className="border-b border-stroke-secondary">
                <AccordionTrigger className="py-4 text-left text-base font-bold text-content-primary">{question}</AccordionTrigger>
                <AccordionContent className="pb-4 text-content-secondary">{answer}</AccordionContent>
            </div>
        </AccordionItem>
    );
};

export default FaqItem;
