import Link from "next/link";
import { SAMPLE_POLICY_URL } from "../utils/constants";
import { PublicConfig } from "@/shared/PublicConfig";

export default function FooterContent() {
    return (
        <div className="flex flex-row flex-wrap gap-6  leading-5">
            <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/ca-notice`} target="_blank">
                Spot NOTICE TO CALIFORNIA RESIDENTS
            </Link>

            <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/maine-notice`} target="_blank">
                Spot NOTICE TO MAINE RESIDENTS
            </Link>

            <div>
                Pre-existing conditions are not covered. Waiting periods, annual deductible, co-insurance, benefit limits, and exclusions may apply. For all terms and conditions
                visit <Link href={SAMPLE_POLICY_URL}>spotpet.com/sample-policy</Link>. Preventive Care reimbursements are based on a schedule. Accident & Illness coverage
                reimbursements are based on the invoice. Products, schedules, discounts, and rates may vary and are subject to change. More information is available at checkout.
            </div>

            <div>
                <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/underwriting`} target="_blank">
                    Insurance products are underwritten by either Independence American Insurance Company (NAIC #26581. A Delaware insurance company located at 11333 N. Scottsdale
                    Rd, Ste. 160, Scottsdale, AZ 85254), or United States Fire Insurance Company (NAIC #21113. Morristown, NJ). Please refer to your policy forms to determine the
                    underwriter for your policy. Insurance is produced by Spot Pet Insurance Services, LLC. (NPN # 19246385. 990 Biscayne Blvd Suite 603, Miami, FL 33132). CA
                    License #6000188. PTZ Insurance Agency Ltd. (NPN: 5328528. domiciled in Illinois with offices at 1208 Massillon Road, Suite G200, Akron, Ohio 44306) is
                    responsible for administration and claims adjudication. (California residents only: PTZ Insurance Agency Ltd., d.b.a PIA Insurance Agency Ltd. CA license
                    #0E36937).
                </Link>
            </div>
        </div>
    );
}
