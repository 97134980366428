// components
import { Card } from "@/shared/components/ui";
import PolicySummaryContent from "./PolicySummaryContent";
import PolicySummaryFooter from "./PolicySummaryFooter";

// utils
// import { UIUtils } from "../../utils/UIUtils";
import { CoverageUtils } from "../../utils/CoverageUtils";

// types
import { PetBreed } from "spot-types/entities/PetBreed";
import { Quote } from "@/shared/types/Quote.interface";

export interface PolicySummaryConfig {
    // Show accordion toggle button. Defaults to true. If false, accordion items will always be open.
    enableAccordionToggle?: boolean;
    // Show pre-discounted total price. Defaults to true. If false, only show the final discounted price (ex: on Thank You page).
    showPreDiscountPrice?: boolean;
    // Defaults to false. If true, will use generic discount label instead of specific discount label. Needed when discount label is not provided by API.
    useGenericDiscountLabel?: boolean;
}

interface PolicySummaryProps {
    quote: Quote;
    breeds: PetBreed[] | undefined;
    config?: PolicySummaryConfig;
}

const PolicySummary = (props: PolicySummaryProps) => {
    const { quote, breeds, config } = props;
    const policies = quote?.policies ?? [];
    const transactionFee = quote?.transactionFee?.value ?? 0;
    const totalPrice = CoverageUtils.calculatePolicyTotal(policies, transactionFee);
    const billingFrequency = quote?.billingInfo?.frequency ?? "monthly";
    const isAnnualBilling = billingFrequency === "yearly";

    return (
        <Card padding="sm" className="border-0 bg-background-brand-b-secondary">
            <PolicySummaryContent quote={quote} breeds={breeds} config={config} isAnnualBilling={isAnnualBilling} />
            <PolicySummaryFooter policiesCount={policies.length} transactionFee={transactionFee} totalPrice={totalPrice} isAnnualBilling={isAnnualBilling} />
        </Card>
    );
};

export default PolicySummary;
