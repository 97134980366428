import React from "react";
import { Heading, IconButton, Dialog, DialogContent, DialogClose } from "@/shared/components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { DialogProps } from "@/shared/types/Modals";
import { PublicConfig } from "@/shared/PublicConfig";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

export const ContactConsentModal: React.FC<DialogProps> = ({ open, onClose }) => {
    function handleOverlayClick() {
        if (onClose) {
            onClose(false);
        }
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    const usfStates = PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_USF;
    const iaicVerbiageV2 = PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V2;
    const iaicVerbiageV3 = PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V3;

    const { appState } = useAppLayerContext();
    const { quoteQuery } = appState;
    const { data: quote } = quoteQuery || {};
    const quoteState = quote?.ratingAddress?.state;
    const isNonApplicantState = usfStates.includes(quoteState as string) || iaicVerbiageV2.includes(quoteState as string);
    const isApplicantState = iaicVerbiageV3.includes(quoteState as string);

    return (
        <div onClick={handleOverlayClick}>
            <Dialog open={open}>
                <DialogContent onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                    <DialogClose asChild>
                        <IconButton onClick={() => onClose && onClose(false)}>
                            <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                        </IconButton>
                    </DialogClose>
                    <div className="flex w-full flex-col gap-2 text-content-primary">
                        {(isNonApplicantState || isApplicantState) && (
                            <>
                                {isNonApplicantState && <p>By clicking the box, you are submitting your application.</p>}
                                <p>
                                    By clicking complete checkout YOU authorize US to deduct the Grand Total amount at the selected frequency from the payment method YOU provided
                                    for full or partial payment of service due.
                                </p>
                            </>
                        )}
                        <Heading level="h3" className="text-lg font-semibold">
                            What’s Covered?
                        </Heading>
                        <p>
                            Each type of coverage includes exam fees, diagnostics, and treatment. We even cover the most advanced treatments, like chemotherapy and surgery, and
                            some you may not expect, such as acupuncture, so you can follow your vet’s recommendations with less worry about cost.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Terms and Conditions
                        </Heading>
                        <p>
                            For your convenience, we’ll automatically issue you a new plan every plan period unless you contact us to cancel. We’ll use your current payment method
                            and notify you in advance of any changes to your plan or premium.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Pre-existing conditions are not covered.
                        </Heading>{" "}
                        <p>
                            Conditions that show symptoms before the plan’s effective date or during a waiting period will be considered pre-existing and ineligible for coverage,
                            even if they haven’t been formally diagnosed or treated by a veterinarian. However, we will no longer consider a condition to be pre-existing if it has
                            been cured and free of symptoms and treatment for 180 days, with the exception of knee and ligament conditions. If a knee or ligament condition occurs
                            before the coverage effective date or during a waiting period, any future ones won’t be covered.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            The information provided must be accurate.
                        </Heading>{" "}
                        <p>Coverage may be cancelled if important information about a pet is intentionally not disclosed or is misrepresented.</p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Pet Residence Restriction
                        </Heading>{" "}
                        <p>
                            Your pet must reside with you at the primary address listed on the declarations page. It is your responsibility to notify us of any change in address. A
                            change in your primary address may result in a change to coverage availability and rates.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Pet parents must be 18 or older to purchase this insurance.
                        </Heading>{" "}
                        <p>
                            Waiting periods apply in the first plan period. Accident & Illness insurance and Accident-Only Coverage begins on the 15th day after the policy
                            effective date listed on policy. Coverage for Preventive care starts on the policy effective date. The waiting period begins on the first effective date
                            of the applicable coverage. Conditions that show symptoms before the plan’s effective date or during a waiting period will be considered pre-existing
                            and ineligible for coverage, even if they haven’t been formally diagnosed or treated by a veterinarian. However, we will no longer consider a condition
                            to be pre-existing if it has been cured and free of symptoms and treatment for 180 days, with the exception of knee and ligament conditions. If a knee
                            or ligament condition occurs before the coverage effective date or during a waiting period, any future ones won’t be covered.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Rules apply for certain discounts
                        </Heading>{" "}
                        <p>
                            If you are eligible for a discount due to your employment status, group membership or affiliation you must inform us of any change in eligibility
                            status.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Authorization to access medical information and records
                        </Heading>{" "}
                        <p>
                            By enrolling, you authorize us to access all medical information and records that we need to assess your pet’s health either prior to or when you submit
                            a claim, and you agree to provide us with any missing medical information and records. You must also provide proof of identity of your pet if we request
                            it.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Fraud Notice{" "}
                        </Heading>
                        <p>
                            NOTICE TO APPLICANTS: Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false
                            information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
                        </p>
                        <p>
                            By clicking complete checkout, YOU authorize US to deduct the amount above at the selected frequency from the payment method YOU provided for full or
                            partial payment of services when due.
                        </p>
                        <Heading level="h3" className="text-lg font-semibold">
                            Fraud Statements
                        </Heading>{" "}
                        <p>
                            NOTICE TO APPLICANTS: Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false
                            information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
                        </p>{" "}
                        <p>
                            <span className="font-semibold">NOTICE TO ALABAMA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO ALASKA APPLICANTS:</span> Any person who knowingly and with intent to defraud any insurance company or other
                            person files an application for insurance or a statement of claim containing any materially false information or conceals, for the purpose of
                            misleading, information concerning any fact material thereto may be guilty of committing a fraudulent insurance act, which is a crime.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO ARIZONA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO ARKANSAS APPLICANTS:</span> Any person who knowingly presents a false or fraudulent claim for payment of a
                            loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement
                            in prison.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO CALIFORNIA APPLICANTS:</span> For your protection, California law requires the following to appear on this
                            form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a
                            loss is guilty of a crime and may be subject to fines and confinement in state prison.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO COLORADO APPLICANTS:</span> It is unlawful to knowingly provide false, incomplete, or misleading facts or
                            information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines,
                            denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading
                            facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a
                            settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory
                            agencies.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO CONNECTICUT APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO DELAWARE APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO DISTRICT OF COLUMBIA APPLICANTS:</span> It is a crime to provide false or misleading information to an insurer
                            for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance
                            benefits if false information materially related to a claim was provided by the applicant.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO FLORIDA APPLICANTS:</span> Any person who knowingly and with intent to injure, defraud or deceive any insurer
                            files a statement of claim or an application containing any false, incomplete or misleading information is guilty of a felony of the third degree.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO GEORGIA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO HAWAII APPLICANTS:</span> For your protection, Hawaii law requires you to be informed that presenting a
                            fraudulent claim for payment of a loss or benefit is a crime punishable by fines or imprisonment or both.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO IDAHO APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any insurer,
                            or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO ILLINOIS APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO INDIANA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO IOWA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any insurer,
                            or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO KANSAS APPLICANTS:</span> Any person who, knowingly and with intent to defraud, presents, causes to be
                            presented or prepares with knowledge or belief that it will be presented to or by an insurer, purported insurer, broker or any agent thereof, any
                            written, electronic, electronic impulse, facsimile, magnetic, oral, or telephonic communication or statement as part of, or in support of, an
                            application for the issuance of, or the rating of an insurance policy for personal or commercial insurance, or a claim for payment or other benefit
                            pursuant to an insurance policy for commercial or personal insurance which such person knows to contain materially false information concerning any fact
                            material thereto; or conceals, for the purpose of misleading, information concerning any fact material thereto may be guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO KENTUCKY APPLICANTS:</span> Any person who knowingly and with intent to defraud any insurance company or other
                            person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning
                            any fact material thereto commits a fraudulent insurance act, which is a crime.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO LOUISIANA APPLICANTS:</span> Any person who knowingly presents a false or fraudulent claim for payment of a
                            loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement
                            in prison.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MAINE APPLICANTS:</span> It is a crime to knowingly provide false, incomplete or misleading information to an
                            insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MINNESOTA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete, or misleading information may be guilty of a
                            crime.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MARYLAND APPLICANTS:</span> Any person who knowingly or willfully presents a false or fraudulent claim for
                            payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be
                            subject to fines and confinement in prison.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MASSACHUSETTS APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MICHIGAN APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MISSISSIPPI APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MISSOURI APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO MONTANA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEBRASKA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEVADA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEW HAMPSHIRE APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEW JERSEY APPLICANTS:</span> Any person who includes any false or misleading information on an application
                            for an insurance policy is subject to criminal and civil penalties
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEW MEXICO APPLICANTS:</span> Any person who knowingly presents a false or fraudulent claim for payment of a
                            loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and
                            criminal penalties.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NEW YORK APPLICANTS:</span> Any person who knowingly and with intent to defraud any insurance company or other
                            person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading,
                            information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not
                            to exceed five thousand dollars and the stated value of the claim for each such violation.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NORTH CAROLINA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO NORTH DAKOTA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO OHIO APPLICANTS:</span> Any person who, with intent to defraud or knowing that he is facilitating a fraud
                            against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO OKLAHOMA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud or deceive any
                            insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO OREGON APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO PENNSYLVANIA APPLICANTS:</span> Any person who knowingly and with intent to defraud any insurance company or
                            other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of
                            misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal
                            and civil penalties.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO RHODE ISLAND APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO SOUTH CAROLINA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO SOUTH DAKOTA APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO TENNESSEE APPLICANTS:</span> It is a crime to knowingly provide false, incomplete or misleading information to
                            an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO TEXAS APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any insurer,
                            or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO UTAH APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any insurer,
                            or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO VERMONT APPLICANTS:</span> Any person who knowingly presents a false statement in an application for insurance
                            may be guilty of a criminal offense and subject to penalties under state law.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO VIRGINIA APPLICANTS:</span> It is a crime to knowingly provide false, incomplete or misleading information to
                            an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO WASHINGTON APPLICANTS:</span> It is a crime to knowingly provide false, incomplete, or misleading information
                            to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO WEST VIRGINIA APPLICANTS:</span> Any person who knowingly presents a false or fraudulent claim for payment of
                            a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and
                            confinement in prison.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO WISCONSIN APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                        <p>
                            <span className="font-semibold">NOTICE TO WYOMING APPLICANTS:</span> Any person who knowingly, and with intent to injure, defraud, or deceive any
                            insurer, or makes any Claim for the proceeds of an insurance Policy containing any false, incomplete or misleading information may be guilty of a
                            felony.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};
