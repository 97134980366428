// packages
import React, { useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";

// contexts
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// components
import { Alert, AlertTitle, AlertDescription } from "@/shared/components/ui/alert";

// utils
import StripeUtils from "./StripeUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import Strings from "@/shared/utils/Strings.constants";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

// media
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const analyticsUtils = new AnalyticsUtils();

export default function StripePaymentElement() {
    const { appState } = useAppLayerContext();
    const { stripePaymentElementError, stripeElements } = appState;
    const phoneHours = `${UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}. ${UIUtils.getPhoneHours("PTZ_US")}`;

    useEffect(() => {
        if (!!stripePaymentElementError?.stripe && !!stripeElements) {
            analyticsUtils.sendDataDogLog("error", "PAYMENT - server validation error", {
                stripeError: stripePaymentElementError.message ?? stripePaymentElementError.header ?? "Unknown error"
            });
            const paymentElement = stripeElements.getElement("payment");
            paymentElement?.focus();
        }
    }, [stripePaymentElementError, stripeElements]);

    return (
        <>
            <PaymentElement id="stripe-payment-element" className="mt-5" options={StripeUtils.paymentElementOptions} />
            {!!stripePaymentElementError?.stripe && (
                <Alert variant="destructive" className="mb-2">
                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                    <AlertTitle className="text-content-danger">{stripePaymentElementError?.header ?? `We couldn't process your payment`}</AlertTitle>
                    <AlertDescription>Double check your payment details and try again, or give us a call: {phoneHours}</AlertDescription>
                </Alert>
            )}
        </>
    );
}
