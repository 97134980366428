import { ReactNode } from "react";

//Icons
import IllustratedHandIcon from "@/shared/media/icons/Illustrated-Heart-Hand.svg";
import IllustratedHospital from "@/shared/media/icons/policy/Hospital.svg";
import ShotVaccine from "@/shared/media/icons/policy/Shot-Vaccine.svg";
import PawHeartbeat from "@/shared/media/icons/policy/Paw-Heartbeat.svg";
import DentalDisease from "@/shared/media/icons/policy/Dental-Disease.svg";
import IllustratedLyphoma from "@/shared/media/icons/policy/Cancer.svg";
import IllustratedHips from "@/shared/media/icons/policy/Hips.svg";
import IllustratedBoneBroken from "@/shared/media/icons/policy/Bone-Broken.svg";

export interface IncludesItem {
    title: string;
    description?: string;
}

export interface SectionData {
    icon?: ReactNode;
    title: string;
    includes: IncludesItem[];
}

export interface CoverageData {
    [key: string]: SectionData;
}

export const general = {
    icon: <IllustratedHandIcon className="size-16" />,
    title: "Spot plans cover eligible diagnostics and treatments for costly accidents like:",
    illnessTitle: "Spot plans cover eligible diagnostics and treatments for costly accidents and illnesses like:",
    includes: [
        { title: "Vet Exam Fees", description: "For when your pet is sick from allergies, stomach issues, ear infections, etc." },
        { title: "Diagnostics", description: "Blood tests, x-rays, MRI’s & CT scans" },
        { title: "Surgery at licensed vets or emergency pet hospitals", description: "Spot plans can be used at any vet in the US or Canada" },
        { title: "Prescription medications/food", description: "Including special diets, supplements, and medications from retailers like PetCo and others" },
        { title: "Specialized Treatments", description: "Including chemotherapy, acupuncture, and more" }
    ]
};

export const accident = {
    icon: <IllustratedHospital className="size-16" />,
    title: "Accident coverage includes:",
    includes: [
        { title: "Swallowed objects" },
        { title: "Toxic ingestions" },
        { title: "Cuts" },
        { title: "Bite wounds" },
        { title: "Insect bites or stings" },
        { title: "Broken bones and other injuries" }
    ]
};

export const illness = {
    icon: <ShotVaccine className="size-16" />,
    title: "Illness coverage includes:"
};

export const illnessSubSections: CoverageData = {
    breedSpecific: {
        icon: <PawHeartbeat className="size-12" />,
        title: "Breed-specific, congenital & genetic conditions",
        includes: [
            { title: "Allergies" },
            { title: "Hip dysplasia" },
            { title: "Cataracts" },
            { title: "Degenerative myelopathy" },
            { title: "Intervertebral disc disease (IVDD)" }
        ]
    },
    dentalIllnesses: {
        icon: <DentalDisease className="size-12" />,
        title: "Dental Illnesses",
        includes: [{ title: "Tooth Trauma", description: "Broken, chipped & fractured teeth" }, { title: "Extractions" }, { title: "Gingivitis" }, { title: "Periodontal disease" }]
    },
    cancer: {
        icon: <IllustratedLyphoma className="size-12" />,
        title: "Cancer",
        includes: [{ title: "Hermangiosarcoma" }, { title: "Lipoma" }, { title: "Lyphoma" }, { title: "Mast cell tumor" }, { title: "Melanoma" }, { title: "Osteosarcoma" }]
    },
    chronicConditions: {
        icon: <IllustratedHips className="size-12" />,
        title: "Chronic conditions",
        includes: [
            { title: "Allergies" },
            { title: "Arthritis" },
            { title: "Diabetes" },
            { title: "Kidney disease" },
            { title: "Hyperthyroidism" },
            { title: "Inflammatory bowel disease " },
            { title: "Seizures" }
        ]
    },
    orthopedicConditions: {
        icon: <IllustratedBoneBroken className="size-12" />,
        title: "Orthopedic conditions",
        includes: [
            { title: "Broken bones" },
            { title: "Cruciate ligament tear" },
            { title: "Disc disease" },
            { title: "Hip dysplasia" },
            { title: "Luxating patella" },
            { title: "Osteosarcoma" }
        ]
    }
};

//Exclusions

export const coverageExclusions = [
    {
        title: "Waiting Periods",
        description: "The time period before coverage begins."
    },
    {
        title: "Pre-existing Conditions",
        description:
            "Most pet insurance providers don’t cover pre-existing conditions, which are conditions that occur before coverage begins or during a waiting period. However, we will no longer consider a condition to be pre-existing if it has been cured and free of symptoms and treatment for 180 days, with the exception of knee and ligament conditions. If a knee or ligament condition occurs before the coverage effective date or during a waiting period, any future ones won’t be covered."
    },
    {
        title: "Cosmetic Procedures",
        description: "Our plans do not cover elective procedures, such as tail docking, ear cropping, and claw removal."
    },
    {
        title: "Breeding Costs",
        description: "Our plans do not cover costs associated with breeding or pregnancy."
    },
    {
        title: "Preventive Care",
        description:
            "Coverage for preventive care, like annual wellness exams, vaccines, and screenings, is not included in this plan. You can add preventive care coverage for a little more per month after you select your base plan."
    }
];
