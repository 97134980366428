import React from "react";

// components
import FaqItem from "./FaqItem";
import { Container } from "@/shared/components/Container";
import { Accordion } from "@/shared/components/ui/accordion";
import { Heading } from "@/shared/components/ui/Heading";

// utils
import { cn } from "@/shared/utils";

interface FaqModuleProps {
    className?: string;
}

const FaqModule = (props: FaqModuleProps) => {
    const { className } = props;

    const faqs: { question: string; answer: string }[] = [
        {
            question: "What is the Best Age to Insure My Pet?",
            answer: "Plans provided by Spot don’t have an upper age limit for new enrollments or coverage. We advise all pet parents to insure their pets at a young age.  You can enroll your pet in a plan from Spot as soon as they’re 8 weeks old.  Don’t let your pet develop a “pre-existing condition” before they’re insured."
        },
        {
            question: "What is a Pre-Existing Condition?",
            answer: ` A pre-existing condition is any injury or illness which occurs or shows symptoms before coverage starts or during a waiting period. A condition is considered pre-existing whether or not it’s been officially diagnosed or treated; all that matters is when it occurred or symptoms first displayed.

Plans provided by Spot do not cover pre-existing conditions. However, with pet insurance plans provided by Spot, this doesn’t mean the condition can’t be covered in the future if the condition is curable. An injury or illness that is curable, cured, and free of treatment and symptoms for 180 days will no longer be regarded as pre-existing, with the exception of knee and ligament conditions. If a knee or ligament condition occurs before the coverage effective date or during a waiting period, any future ones won’t be covered.
`
        },
        {
            question: "Does Spot Offer a Plan for Preventive Care Coverage?",
            answer: "Yes, you can add preventive care coverage to your policy for a little more cost. When you add preventive care coverage, you’ll be reimbursed up to the scheduled amount for things that help protect your pet from getting sick. You don’t have to satisfy your deductible before you can be reimbursed for preventive care coverage benefits, and there is no waiting period for them either."
        },
        {
            question: "How are My Pet Insurance Premiums Calculated?",
            answer: "In general, your premium is based on your pet’s age, your pet’s breed, and where your pet lives. Some other factors that impact your premium are: the plan you choose, your deductible, your reimbursement percentage, if you have included preventative care coverage or not, and the discounts for which you are eligible."
        }
    ];
    return (
        <Container className={cn("mx-auto flex flex-col gap-2 py-8", className)}>
            <div className="mx-2 flex flex-col gap-2 text-center text-content-primary">
                <Heading level="h2" className="text-xl font-medium">
                    Frequently Asked Questions
                </Heading>
                <p className="text-sm text-content-secondary">{"Have questions about pet insurance and how it works? Don't worry, we've got you covered."}</p>
            </div>
            <div className="mx-auto w-full max-w-[720px]">
                <Accordion type="single" defaultValue="item-1" collapsible>
                    {faqs.map((faq, index) => (
                        <FaqItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </Accordion>
            </div>
        </Container>
    );
};

export default FaqModule;
