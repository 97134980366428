// packages
import React from "react";
import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

// components
import SiteHeader from "@/shared/components/SiteHeader";
import { PolicyEditor } from "@/quote-ptz-us/src/components/PolicyEditor";
import { UserContactEditor } from "@/shared/components/UserContactEditor";
import { CoverageEditor } from "@/shared/components/CoverageEditor";
import { BillingEditor } from "@/quote-ptz-us/src/components/BillingEditor";
import SiteFooter from "@/shared/components/SiteFooter";
import FooterContent from "./src/components/FooterContent";
import FaqModule from "./src/components/FaqModule";
import GiftCardDisclaimer from "@/quote-ptz-us/src/components/GiftCardDiscaimer";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import Exclusions from "@/quote-ptz-us/src/components/Exclusions";
import { PriceInfo } from "@/shared/components/PriceInfo";
import { BillingOutage } from "@/shared/components/BillingOutage";
import modalContent from "@/quote-ptz-us/src/components/FullCoverageDetailsModal";
import { FreePetDisclaimer } from "@/quote-ptz-us/src/components/FreePetDisclaimer";
import { ContactDisclaimer } from "@/quote-ptz-us/src/components/ContactDisclaimer";

// context
import { AppStateInterface } from "@/shared/contexts/AppLayer";

// config
import { PublicConfig } from "@/shared/PublicConfig";

// utils
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { PtzUsDataUtils } from "@/quote-ptz-us/src/utils/PtzUsDataUtils";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import Strings from "@/shared/utils/Strings.constants";
import { PresetCoverageLevel } from "@/shared/utils/CoverageUtils";
import { TrackingUtils } from "@/shared/utils/TrackingUtils";
import { US_FOOTER_LINKS } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

// media
import { SpotLogo } from "@/shared/media/SpotLogo";

// types
import { FormConfig, FormStep } from "@/shared/types/Form";
import { UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PartialQuoteSchema, BillingStepSchema, QuoteFinalized, PolicySchema, Quote } from "@/shared/types/Quote.interface";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { SpotErrorMessage } from "@/shared/types/SpotAPI";
import { SubmitHandler, UseFormSetValue } from "react-hook-form";
import { StepperConfig } from "@/shared/components/Stepper";
import { SAMPLE_POLICY_URL } from "./src/utils/constants";
import { StripeProvider } from "@/shared/contexts/StripeProvider";
import { EmailUtils } from "@/shared/utils/EmailUtils";
import { StatsigNameFields } from "@/shared/types/Statsig";

type UsQuoteFormProps = {
    underwriter: PetUnderwriterType;
    updateAppState: (state: Partial<AppStateInterface>) => void;
    updateQuote?: UseFormSetValue<Quote>;
    isUpdating: boolean;
    queryClient: QueryClient;
    setQuoteId: (quoteId: string) => void;
    onSubmit: SubmitHandler<QuoteFinalized>;
    analyticsUtils: AnalyticsUtils;
    currentStep: UsQuoteFormStepIds;
    updateCurrentStep: (stepId: UsQuoteFormStepIds, quoteId?: string) => void;
    isApplyAllHidden?: boolean;
    nameFieldValues: StatsigNameFields;
    showBillingSubmitButton?: boolean;
};

const FORM_ID = PublicConfig.PTZ_US.FORM_ID;
const ACTIVE_OUTAGES = PublicConfig.PTZ_US.ACTIVE_OUTAGES;

export type UsQuoteFormStepIds = "pets" | "coverage" | "billing-outage" | "billing";

export const getUsQuoteForm = ({
    underwriter,
    updateAppState,
    isUpdating,
    queryClient,
    setQuoteId,
    onSubmit,
    analyticsUtils,
    currentStep,
    updateCurrentStep,
    updateQuote,
    nameFieldValues,
    showBillingSubmitButton
}: UsQuoteFormProps): FormConfig<UsQuoteFormStepIds, Quote> => {
    const quoteApi = new QuoteAPI(underwriter);
    const builderUtils = new BuilderUtils(underwriter);
    const { showFirstName, showLastName } = nameFieldValues;

    const emailUtils = new EmailUtils(underwriter, queryClient);

    const getPolicyStepFieldStyles = () => {
        if (!showFirstName && !showLastName) {
            return {
                zipCode: "order-1 lg:col-span-2",
                email: "order-2 lg:col-span-2",
                phone: "order-3 lg:col-span-2"
            };
        }
        if (showFirstName && !showLastName) {
            return {
                zipCode: "order-1 lg:col-span-3",
                email: "order-2 lg:col-span-3",
                firstName: "order-3 lg:col-span-3",
                phone: "order-4 lg:col-span-3"
            };
        }
        return {
            zipCode: "order-1 lg:col-span-3",
            email: "order-2 lg:col-span-3",
            firstName: "order-3 lg:col-span-2",
            lastName: "order-4 lg:col-span-2",
            phone: "order-5 lg:col-span-2"
        };
    };

    const getPolicyStepSchema = () => {
        if (!showFirstName && !showLastName) {
            return UsPolicyStepSchema.omit({ firstName: true, lastName: true });
        }
        if (showFirstName && !showLastName) {
            return UsPolicyStepSchema.omit({ lastName: true });
        }
        return UsPolicyStepSchema;
    };

    const stepsConfig: FormStep<Quote, UsQuoteFormStepIds>[] = [
        {
            id: "pets",
            continueButtonTitle: Strings.PTZ_US.SELECT_COVERAGE_TEXT,
            stepSchema: getPolicyStepSchema(),
            title: Strings.ENTER_PET_INFO,
            getInitialValues: quote => PtzUsDataUtils.getPolicyStepInitialValues(quote),
            disclaimerContent: props => (
                <>
                    <CustomizationSlot
                        type="above-disclaimer"
                        data={props.value}
                        formId={FORM_ID}
                        formStepId="pets"
                        formData={props.value?.extra?.formData}
                        updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                    />
                    <FreePetDisclaimer />
                </>
            ),
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const shouldTrackCheckoutStarted = !values?.id;

                if (!!values?.email && PublicConfig.ENVIRONMENT === "production") {
                    try {
                        const emailIsRegistered = await emailUtils.verifyEmailRegistration(values.email);
                        if (emailIsRegistered) {
                            updateAppState({ asyncErrors: [{ id: "account-exists" }], isQuoteUpdating: false });
                            return false;
                        }
                    } catch (error) {
                        console.error("Error verifying email", error);
                    }
                }

                const emailDebounceCheck = await emailUtils.handleEmailDebounce({
                    email: values.email,
                    debounce: values?.extra?.debounce,
                    updateAppState
                });

                if (!emailDebounceCheck) return false;

                try {
                    const policyData = QuoteDataUtils.quoteToPetQuote({
                        ...values,
                        lastStepID: "coverage",
                        marketing: {
                            hasEmailConsent: true,
                            hasSMSConsent: true
                        },
                        extra: {
                            ...values.extra
                        }
                    });

                    const updatedPetQuote = await quoteApi.updateQuote(policyData);

                    if (!updatedPetQuote?.quoteId) {
                        throw new Error("No quote ID in response");
                    }
                    const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
                    queryClient.setQueryData(["quote", updatedPetQuote.quoteId], updatedQuote);
                    setQuoteId(updatedPetQuote.quoteId);

                    if (shouldTrackCheckoutStarted) {
                        const quoteProperties = TrackingUtils.buildTrackingPayload(updatedPetQuote);
                        analyticsUtils?.trackSegmentEvent(`Checkout Started`, quoteProperties);
                    }
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    return updatedQuote;
                } catch (error) {
                    if (isAxiosError(error)) {
                        const errorStatus = error?.response?.status ?? 0;
                        if (quoteApi.isSpotApiError(error.response?.data)) {
                            const asyncErrors = quoteApi.getErrorIds(error?.response?.data);
                            updateAppState({ asyncErrors, isQuoteUpdating: false });
                            return false;
                        }
                        // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                        if (errorStatus >= 400) {
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return false;
                        }
                    }
                    return false;
                }
            },
            render: props => {
                return (
                    <>
                        <PolicyEditor {...props} />
                        <UserContactEditor
                            config={{
                                showFirstName: true,
                                showLastName: true,
                                showMobilePhone: true,
                                underwriter: "ptz-us",
                                zipField: {
                                    label: Strings.ZIP_CODE,
                                    mask: UIUtils.ZipCodeMask,
                                    showGiftCardWrapper: true,
                                    placeholder: " "
                                }
                            }}
                            styles={{
                                wrapper: "grid grid-cols-1 gap-6 lg:grid-cols-6",
                                fields: getPolicyStepFieldStyles()
                            }}
                            {...props}
                        />
                        <div className="mt-2 flex flex-col gap-4 text-xs text-content-secondary">
                            <ContactDisclaimer />
                            <GiftCardDisclaimer alignLeft />
                        </div>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId="pets"
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                        />
                    </>
                );
            }
        },
        {
            id: "coverage",
            isMaxDeterministicStep: true,
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: false });
                return (
                    <PriceInfo
                        totalPrice={roundedPrice}
                        quoteId={props?.value?.id}
                        policiesCount={props?.value?.policies?.length ?? 0}
                        discountsAmount={discountsAmount}
                        variant="inline"
                    />
                );
            },
            continueButtonTitle: Strings.PROCEED_TO_CHECKOUT,
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId="coverage"
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            render: props => {
                const coveragePresetData: PresetCoverageLevel[] = [
                    {
                        name: "Basic",
                        config: {
                            type: ["accident", "illness"],
                            deductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
                            reimbursementPercent: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE,
                            annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT
                        }
                    }
                ];

                const discountCode = props.value?.discountCode?.toLowerCase();

                const showTakeoverProvision = PublicConfig.TAKEOVER_PROVISION_PCODES.includes(discountCode) || (discountCode && discountCode.includes("ebtp"));

                return (
                    <>
                        <CoverageEditor
                            {...props}
                            editorConfig={{
                                formId: FORM_ID,
                                title: "Create your plan",
                                includeTransactionFee: false,
                                coveragePresetData,
                                termsInModal: ["annualDeductible", "annualLimit", "reimbursement"],
                                samplePolicyUrl: SAMPLE_POLICY_URL,
                                modalContent: modalContent,
                                preventiveConfig: {
                                    labels: {
                                        basic: "Gold",
                                        advanced: "Platinum"
                                    }
                                },
                                exclusions: <Exclusions />,
                                customizationSlot: (
                                    <CustomizationSlot
                                        type="above-cta"
                                        data={props.value}
                                        formId={FORM_ID}
                                        formStepId="coverage"
                                        formData={props.value?.extra?.formData}
                                        updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                                    />
                                ),
                                showTakeoverProvision: showTakeoverProvision
                            }}
                        />
                    </>
                );
            },
            hidePreviousSteps: true,
            allowSubmit: values => {
                if (!values) return false;
                if (isUpdating) return false;

                let isValid = true;

                values?.policies?.forEach(policy => {
                    if (!policy) {
                        isValid = false;
                        return;
                    }

                    // Using safeParse for validation
                    const validationResult = PolicySchema.safeParse(policy);

                    if (!validationResult.success) {
                        isValid = false;
                    }
                });

                // Return false if any policy was invalid, true otherwise
                return isValid;
            },
            allowContinue: async values => {
                if (!values) return Promise.resolve(false);
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });

                try {
                    const policyData = QuoteDataUtils.quoteToPetQuote({
                        ...values,
                        lastStepID: ACTIVE_OUTAGES.includes("ptz-us-billing-api") ? "billing-outage" : "billing"
                    });

                    const updatedPetQuote = await quoteApi.updateQuote(policyData);

                    if (!updatedPetQuote?.quoteId) {
                        throw new Error("No quote ID in response");
                    }
                    const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
                    queryClient.setQueryData(["quote", updatedPetQuote.quoteId], updatedQuote);
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    return true;
                } catch (error) {
                    if (isAxiosError(error)) {
                        const errorStatus = error?.response?.status ?? 0;

                        if (quoteApi.isSpotApiError(error.response?.data)) {
                            const asyncErrors = quoteApi.getErrorIds(error?.response?.data);
                            updateAppState({ asyncErrors, isQuoteUpdating: false });
                            return false;
                        }

                        // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                        if (errorStatus >= 400) {
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return false;
                        }
                    }
                    return false;
                }
            }
        },
        {
            id: "billing-outage",
            hideContinueButton: true,
            hidePreviousSteps: true,
            shouldSkip: !ACTIVE_OUTAGES.includes("ptz-us-billing-api"),
            render: props => <BillingOutage {...props} underwriter={underwriter} />
        },
        {
            id: "billing",
            hideContinueButton: !showBillingSubmitButton,
            stepSchema: BillingStepSchema,
            getInitialValues: quote => QuoteDataUtils.getBillingStepInitialValues(quote),
            continueButtonTitle: "Complete Checkout",
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: true });
                return (
                    <PriceInfo
                        totalPrice={roundedPrice}
                        quoteId={props?.value?.id}
                        policiesCount={props?.value?.policies?.length ?? 0}
                        discountsAmount={discountsAmount}
                        variant="inline"
                    />
                );
            },
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                if (!values.phone) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true, showLoaderDialog: true });
                try {
                    const extraWithThankYou = {
                        ...values.extra,
                        thankYouURL: `${window.location.origin}${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou?uw=${underwriter}&quoteId=${values.id}`
                    };
                    await onSubmit({ ...values, extra: extraWithThankYou } as QuoteFinalized);
                    updateAppState({ isQuoteUpdating: false });
                    return true;
                } catch (error) {
                    updateAppState({ showLoaderDialog: false, isQuoteUpdating: false });
                    if (error instanceof Error && !!error?.message && error?.message.toLowerCase().includes("billing zipcode")) {
                        const asyncErrors = [{ id: `invalid-postal-code`, at: "zipcode" }] as SpotErrorMessage[];
                        updateAppState({ asyncErrors });
                    }
                    console.error(error);
                    return false;
                }
            },
            render: props => {
                const { roundedPrice } = CoverageUtils.getPriceInfoData({ value: props.value, includeTransactionFee: true });
                return (
                    <React.Fragment>
                        <StripeProvider amount={roundedPrice}>
                            <BillingEditor {...props} />
                        </StripeProvider>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId="billing"
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                        />
                    </React.Fragment>
                );
            },
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId="billing"
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            hidePreviousSteps: true
        }
    ];

    const stepsForStepper: StepperConfig<UsQuoteFormStepIds>[] = stepsConfig.map(step => ({
        stepValue: step.id as UsQuoteFormStepIds,
        shouldSkip: step.shouldSkip
    }));

    return {
        id: FORM_ID,
        schema: PartialQuoteSchema,
        header: (value, otherValues, isUpdating) => {
            return (
                <SiteHeader
                    quote={value}
                    updateQuote={updateQuote}
                    formID={FORM_ID}
                    underwriter={underwriter}
                    phoneNumber={Strings.PTZ_US.PHONE_NUMBER}
                    logo={<SpotLogo />}
                    currentStep={currentStep}
                    steps={stepsForStepper}
                    updateCurrentStep={updateCurrentStep}
                />
            );
        },
        steps: stepsConfig,
        footer: (value, otherValues, isUpdating) => {
            return (
                <React.Fragment>
                    {currentStep === "coverage" && (
                        <>
                            <FaqModule className="mt-7 pb-0 lg:mt-10" />
                        </>
                    )}
                    <SiteFooter
                        underwriter={underwriter}
                        formID={FORM_ID}
                        links={US_FOOTER_LINKS}
                        content={<FooterContent />}
                        copyright={Strings.PTZ_US.COPYRIGHT_TEXT}
                        hasOffset={false}
                    />
                </React.Fragment>
            );
        }
    };
};
